require('./bootstrap');
require('@splidejs/splide');
require('@splidejs/splide-extension-auto-scroll');
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { Video } from '@splidejs/splide-extension-video';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
//Get the button
window.onload = function() {

  if(window.innerWidth<1081){
  $('#creation-1').show();
  $('#creation-2').show();
  $('#creation-3').show();
  $('#creation-4').show();
  
  }
  if(window.innerWidth<1081){
  $('#creation-1-title').hide();
  $('#creation-2-title').hide();
  $('#creation-3-title').hide();
  $('#creation-4-title').hide();}
  

  if($('#succ-alert').length){
    setTimeout(()=>$('#succ-alert').hide(),4000)
  }
  if($('#about_usMore').length){
    $("#about_usMore").click(()=>{
        $("#about_Choclo").show(400,"swing")
        
        $("#about_usMore").hide();
        }
    )
    $("#about_usLess").click(()=>{
      $("#about_Choclo").hide(400,"swing")
      
      $("#about_usMore").show();
      }
    )
  }
  if($('#partner-info').length){
    $("#Open-Form").click(()=>{
        $("#partner-info").hide(400,"swing");
        setTimeout(()=>$("#partner-form").show(400,"swing"),401)
        

        }
    )
    $("#partner-form-close").click(()=>{
      $("#partner-form").hide(400,"swing");
      setTimeout(()=>$("#partner-info").show(400,"swing"),401)
      

      }
  )
   
    
  }
  $("#Open-Form-Review").click(()=>{
    $("#Open-Form-Review").hide(400,"swing");
    setTimeout(()=>$("#Review-Form").show(400,"swing"),401)
    

    }
    )
    $("#Open-More-Review").click(()=>{
      $("#Open-More-Review").hide(400,"swing");
      setTimeout(()=>$("#ShowMoreReviews").show(400,"swing"),401)
      
  
      })
let mybutton = document.getElementById("btn-back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
scrollFunction();
};


function scrollFunction() {
    
    if (
    document.body.scrollTop > 20 ||
    document.documentElement.scrollTop > 20 &&
    (document.documentElement.scrollHeight - document.documentElement.scrollTop) >= document.documentElement.clientHeight +30
    ) {
      
    mybutton.style.display = "block";
    } else {
    mybutton.style.display = "none";
    }
}
// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener("click", backToTop);

function backToTop() {
document.body.scrollTop = 0;
document.documentElement.scrollTop = 0;
}

$(function() {
    $(document).click(function (event) {
      $('.navbar-collapse').collapse('hide');
    });
  });
  $(".PartnerOpen").on("click", function() {
    
    $('#Partnership').modal('show')
   
  
  });
  $(".Choclazo").on("click", function() {
    
    $('#Choclazo-modal').modal('show')
    
  
  });
  $(".pop").on("click", function() {
      
      if(true){
      $('#imagepreview').attr('src', $(this).children().css('background-image').replace(/^url\(['"](.+)['"]\)/, '$1')); // here asign the image to the modal when the user click the enlarge link
      $('#imagemodal').modal('show'); // imagemodal is the id attribute assigned to the bootstrap modal, then i use the show function
      $(window).focus(function() {
        //$('#imagemodal').modal('hide');
      });
      $(window).blur(function() {
        //$('#imagemodal').modal('hide');
      });
      }

  });
  $(".ReviewOpen").on("click", function() {
    $('#reviews-modal').modal('show')
    if(window.innerWidth>900){
    
    }
  
  });
 


 $(".close").on("click", function() {
     // here asign the image to the modal when the user click the enlarge link
    $('#imagemodal').modal('hide'); // imagemodal is the id attribute assigned to the bootstrap modal, then i use the show function
    $('#Choclazo-modal').modal('hide'); // imagemodal is the id attribute assigned to the bootstrap modal, then i use the show function
    $("#Partnership").modal('hide');
   
    $('#reviews-modal').modal('hide');
    
 });
 
 if(location.pathname.split('/')[1]==""){
 mount2();
 mount();
}
function mount(){
    var splide;
    if(document.documentElement.clientWidth<900){
    splide = new Splide( '#splide-xs'  ,{
        autoScroll: {
            speed: 0.4,
          }
        });
    }
    else{
        splide = new Splide( '#splide-lg' ,{
        autoScroll: {
            speed: 0.3,
          },
          perPage    : 2,
          autoplay:false
        });
    }
    
    if(splide){
    splide.mount({ AutoScroll});}
}
function mount2(){
  var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  var splide;
  if(iOSSafari){
    $('#splide-videos-2').show();
    
    splide = new Splide( '#splide-videos-2'  ,{
      type:"loop",
      pagination:false,
      autoplay:true,
      lazyLoad:'nearby'
          });
      splide.mount();
      
  }
  else{
      $('#splide-videos-1').show();
      splide = new Splide( '#splide-videos-1'  ,{
        type:"loop",
        heightRatio:0.46875,
        video: {
          loop         : false,
          mute         : true,
          autoplay:true,
          hideControls:true,
          disableOverlayUI:true,
          
          playerOptions: {
          
            htmlVideo: {controls:false, muted         : true,autoplay:true,playsInline:true},
          }
        }
        , 

        autoScroll: false
          ,pagination:false,
          autoplay:false
            
        });
    
    if(splide){
    splide.mount({ AutoScroll,Video});
    
      splide.on( 'video:ended', function() {
        splide.go('>');
      } );
    }
  }
  
}






    $('#cr-1').hover(function(){hoverer("#creation-1",'#cr-1')},function(){hider("#creation-1")});
    $('#cr-2').hover(function(){hoverer("#creation-2",'#cr-2')},function(){hider("#creation-2")});
    $('#cr-3').hover(function(){hoverer("#creation-3",'#cr-3')},function(){hider("#creation-3")});
    $('#cr-4').hover(function(){hoverer("#creation-4",'#cr-4')},function(){hider("#creation-4")});
  


  function hoverer(id,parent) {
    
    
    setTimeout(() => {
     
      if (idIsHovered(parent)){
      
      $(id+"-title").hide();
      $(id).show(600,'swing');
      }
      
    },500)
    //$this.text("I'm replaced!");
  }
  function hider(id) {
    // caching $(this).
    

    if($(id).is(":visible")){
    $(id).hide(300,'swing');
    $(id+"-title").show(400);
    }
    
    //$this.text($this.data('defaultText'));
    
}
function idIsHovered(id){
  return $( id + ":hover").length > 0;
}

//Prevents back button from being activated when modals/navbar are active. Instead closes the modal/navbar
if(window.innerWidth<900){
  
  
  history.pushState(null, document.title, location.href);

  window.addEventListener('popstate', function (event)
  {
    
    if($("#Partnership").is(":visible")||$("#Choclazo-modal").is(":visible")||$("#imagemodal").is(":visible")||$("#reviews-modal").is(":visible")||$(".navbar-collapse").is(":visible")){
      
      history.pushState(null, document.title, location.href);
      
      $("#Partnership").modal('hide');
      $('#Choclazo-modal').modal('hide');
      $('#imagemodal').modal('hide');
      $('#reviews-modal').modal('hide');
      $('.navbar-collapse').collapse('hide');
    }
  });
}
var myEle = document.getElementById("gallery-anchor");
if(myEle){
    myEle.scrollIntoView();
}
}
